@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

body, *{
  font-family: 'Muli', sans-serif;

}

body{
  width: 100vw;
  overflow-x: hidden;
}