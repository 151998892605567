@import '_mixins';

.demo-form{
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__info{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    @include lg{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  &__message{
    width: 300px;
    @include lg{
      width: 620px;
    }
  }

  &__subject{
    order: 4;
    @include lg{
      order: 3;
    }
  }

  &__phone{
    order: 3;
    @include lg{
      order: 4;
    }
  }

  &__field{
    @include lg {
      margin-right: 20px;
    }
    min-width: 300px;
  }

  &__card{
    @include lg{
      width: 650px !important;
    }
  }
}

.demo-form--submitted{

  text-align: center;
  margin-top: 100px;

  width: 320px;
  height: 200px;

  @include lg{
    width: 650px !important;
    height: 200px;
  }
}
