@import 'mixins';

#footer{
  width: 100%;
  background-color: #4c4c4c;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-components{
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    @include md{
      padding-left: 150px;
      padding-right: 150px;
    }
    @include lg{
      padding-left: 250px;
      padding-right: 250px;
    }
  }

  #logo{
    width: 100px;
    align-self: center;
    margin-right: 100px;
    margin-top: 40px;
  }

  .footer__icon{
    transform: scale(1.5);
  }

}

.footer-box{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: 15px;
  margin-bottom: 15px;


  &__header{
    color: #FFFFFF;
  }

  &__item{
    color: white;
  }
  a, a:visited{
    color: white;
    text-decoration: none;
  }

  &__group{
    width: 300px;
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
}

#support{
  align-self: flex-start;
  justify-self: flex-start;
}

#copyright{
  background-color: #424242;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  flex-direction: row;
  margin-bottom: 0;
  color: #a6a6a6;
  font-size: 12px;
  .definitechs-info{
    display: flex;
    justify-content: space-between;
  }
  .credit-icon{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 5px;
    div{
      margin-top: 3px;
    }
    a, a:visited{
      color: black;
      margin-left: 2px;
      margin-right: 2px;
    }
  }
}

#copyright div{
  display: flex;
  align-items: center;
}

#linked-in{
  width: 30px;
}

#social{
  margin-top: 3px;
}
