#contact{
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  @include lg{
    flex-direction: row;
  }

  .details{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include lg{
      width: 50%;
    }
    background-color: #535353;
  }

  .header{
    color: #e0e0e0;
    margin-top: 2em;
    font-size: 3em;
  }

  .info{
    background-color: white;
    margin-top: 2em;
    width: 20em;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 15em;
    padding: 20px;
    color: #535353;
    margin-bottom: 20px;

    a{
      font-size: 20px;
      color: #0277bd;
      text-decoration: none;
      display: block;
      margin-bottom: 20px;
    }
    p{
      font-size: 20px;
      margin-top: 0;
    }
  }

  &__main-icon {
    display: block;
  }
}

#map-wrapper{
  width: 100%;
  height: 50vh;
  @include lg{
    width: 50%;
    height: 100vh;
  }
}