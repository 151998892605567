@import 'mixins';

#our-jobs-img{
  width: 400px;
  max-width: 80vw;

}

#our-jobs {
  padding-bottom: 30px;
  padding-top: 30px;
  margin-top: 50px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @include lg{
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
  }

  .content{
    font-size: 1.5em;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    @include lg{
      width: 35%;
      margin-left: 40px;
      justify-content: flex-end;
    }
    order: 2;
    @include lg{
      order: 1;
    }
  }

  .images{
    margin-top: 20px;
    align-self: center;
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;
  }
}

.list-circle{
  fill: #326666;
  transform: scale(0.5);
}