#leadership{
  min-height: calc(100vh - 265px);
  padding-bottom: 20px;
  padding-top: 90px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .linked-in-img{
    width: 20px;
    margin-left: 120px;
    margin-bottom: 20px;
  }
  .leadership-card{
    margin-right: 40px;
    margin-top: 70px;
    width: 300px;
    padding-top: 190px;
    overflow: visible;
    :hover {
      background-color: #ffffff !important;
      .leadership-img{
        top: -270px;

      }
    }
  }
  .leadership-img{
    width: 270px;
    margin-left: 15px;
    border-radius: 10px;
    position: absolute;
    top: -220px;
    z-index: 100;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  }

}