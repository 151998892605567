#about{
  text-align: center;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  padding: 30px 30px 30px 30px;
  box-shadow: none;
  .header{
    font-size: 2.5em;
  }
  .content{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 1.2em;
  }
}

.about{
  &__card{
    width: 250px;
    padding: 20px 60px;
    margin: 20px;
    text-align: center;
    background-color: #f6f6f6;
    color: #757575;
    h2{
      color: black;
    }
  }
  &__icon{
    width: 100px;
    fill: #346666;
  }
}

#idea-image{
  path{
    fill: #000;
  }
}