@import 'mixins';
#product {
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  background-color: #f6f6f6;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  @include lg{
    flex-direction: row;
    justify-content: space-around;
  }

  .content{

    align-self: center;
    width: 80%;
    @include lg{
      align-self: flex-start;
      width: 50%;
    }
    .header {
      font-size: 2.5em;
      margin-bottom: 10px;
    }
    p{
      color: #757575 ;
      font-size: 1.2em;
    }
  }
  .images{
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

#product-image{
  width: 500px;
  max-width: 80vw;

}

#tower-image{
  height: 300px;

}

#product-right {
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  background-color: #f6f6f6;
  padding-bottom: 30px;
  padding-top: 30px;
  margin-top: 50px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @include lg{
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
  }

  .content-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 20px;
    @include lg{
      width: 35%;
      margin-left: 40px;
      justify-content: flex-end;
    }
    .content{
      width: 350px;
      .header {
        font-size: 2.5em;
        margin-bottom: 10px;
      }
      .MuiTypography-subheading-161{
        color: #757575 ;
        font-size: 1.2em;
      }
    }
    order: 2;
    @include lg{
      order: 1;
    }
  }

  .images{
    margin-top: 20px;
    align-self: center;
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;
  }

  .MuiListItemIcon-root-147{
    color: red;
  }
  .MuiListItemText-root-148{
    padding: 0 16px
  }
  .list-circle{
    fill: #747474 !important;
  }
  .MuiListItem-root-135{
    align-items: flex-start;
  }
}

.list-circle{
fill: #326666;
transform: scale(0.5);
}

