@import '_mixins';

#tabs{
  display: none;
  @include md {
    display: flex;
  }
}

#sidebar{
  display: block;
  @include md {
    display: none;
  }
}

#menu-icon{
  display: block;
  @include md {
    display: none;
  }
}