.card__title{
  display: inline-block;
  margin-bottom: 0;
  margin-top: -20px;
  margin-left: 20px;
  border-radius: 5px;
  padding: 20px;
  color: white;
  background: linear-gradient(60deg, #ffa726, #fb8c00);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4);
}

.card{
  overflow: visible;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14) !important;
  margin: 20px;
}