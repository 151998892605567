#demo{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f6f6;
  padding-right: 40px;
  padding-left: 40px;
  box-sizing: border-box;

  .header{
    text-align: center;
    font-size: 3em;
  }
}