@import 'mixins';

#home{
  width: 100%;
  position: relative;
  color: #e0e0e0;
}

.pane{
  width: 100vw;

  &__header{
    font-size: 2.5em;
    z-index: 1;
    margin-top: 120px;
    left: 50%;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    text-align: center;
    color: #e0e0e0;

    @include md{
      font-size: 5em;
      width: 500px;
    }

  }

  &__btn{
    transform: translateX(-50%) ;
    left: 50%;
    margin-top: 10px;
    background-color: rgb(180,180,180);
    border-radius: 50vh;
    color: black;
    font-weight: bold;
    &:hover{
      background-color: rgb(150,150,150);
    }
    margin-bottom: 100px;
  }
}

#pane-1{
  background: linear-gradient(
                  rgba(0, 0, 0, 0.4),
                  rgba(0, 0, 0, 0.4)
  ) , url('../images/road-home.png')  center;
  background-size: 100vw 60vh;
  @include md{
    background-size: 100vw 100vh;
  }
  background-repeat: no-repeat;
  background-color: black;

}

#pane-2{
  background: linear-gradient(
                  rgba(0, 0, 0, 0.4),
                  rgba(0, 0, 0, 0.4)
  ) , url('../images/road-home.png')  center;
}

#right-slide{
  position: absolute;
  right: 0;
  top: calc(50% - 20px);
}

#left-slide{
  position: absolute;
  left: 0;
  top: calc(50% - 20px);
}

.slider-icon{
  transform: scale(2);
}

#topo3d-ref{
  position: absolute;
  bottom: 0px;
  left: 10px;
  font-weight: 500;
  font-size: 10px;
}