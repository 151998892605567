@import 'mixins';

#featured{
  width: 100vw;
  overflow: hidden;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  background-color: white;

  .brands{
    width: calc(100% - 100px);
    display: flex;
    align-items: center;
    overflow-y:hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    img{
      height: 10em;
      margin: 0.6em;
    }
    &__fondation{
      height: 5em !important;
    }
  }


  .svg_icons{
    transform: scale(3);
  }
}

.brands::-webkit-scrollbar { width: 0 !important }
